<template>
  <span v-html="countdownText"/>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useUiStore } from '@/store/ui';

const props = defineProps({
  time: {
    type: String,
    default: null,
  },
  endedText: {
    type: String,
    default: null,
  },
});

const emits = defineEmits([
  'ended',
]);

const { $dayjs, } = useNuxtApp();

const uiStore = useUiStore();
const {
  secondsTicker,
} = storeToRefs(uiStore);

const countdownText = ref(null);
const ended = ref(false);

setExpiresIn();

function setExpiresIn() {
  const now = $dayjs();
  const endDate = $dayjs(props.time);
  const expiresIn = endDate.diff(now, 's');

  if (expiresIn <= 0) {
    if (!ended.value) {
      ended.value = true;
      emits('ended');
    }

    countdownText.value = props.endedText || 'soon';
    return;
  }

  ended.value = false;

  countdownText.value = `<span class="tabular-nums">${endDate.fromNow()}</span>`;
}

watch(
  () => secondsTicker.value,
  () => {
    setExpiresIn();
  }
);
</script>
