<template>
  <div v-if="ready" class="min-h-[16px]">
    <div
      v-if="!pendingWithdrawal && !isWithdraw"
      class="text-center text-sm leading-tight text-slate-100 mb-2"
    >
      <p v-if="(hasFee && availability.outcome === 'Available' && requestAmount > 0)">
        {{ withdrawalFeeAmount }} {{ currency }} Gas Fee applies.
      </p>
      <p v-else-if="flatFee">
        {{ availability.fee.configuration.amount }} {{ currency }} Gas Fee applies.
      </p>
      <p v-else-if="percentageFee">
        Gas Fee of {{ availability.fee.configuration.amount }}% {{ currency }} up to {{ availability.fee.configuration.maxAmount }} {{ currency }} applies.
      </p>
    </div>
    <div class="text-center text-sm leading-tight text-slate-100">
      <p v-if="availability?.outcome === 'WithdrawalLimitReached' && !hasRemainingWithdrawalLimit">
        You have already swapped the maximum amount allowed for a {{ withdrawalLimitTime }}. You can swap another {{ availability?.limitRemainingAfterExpiry }} {{ currency }} <CountdownMain :time="availability?.limitExpiryTime" @ended="loadWithdrawalAvailability()"/>
      </p>

      <div
        v-if="(pendingWithdrawal && !showWithdrawalCompleted) || availability?.outcome === 'MaximumPendingWithdrawals'"
        class="animate-pulse flex justify-center items-center text-white mb-2"
      >
        <span v-if="!pendingWithdrawal && availability?.outcome === 'MaximumPendingWithdrawals' && requireConfirm" class="inline-block">
          Check your email to confirm your swap request.
        </span>
        <span v-else class="inline-block">
          {{ isWithdraw ? 'Pending Withdrawal' : 'Pending Swap' }}
        </span>
        <a
          v-if="txUrl"
          :href="txUrl"
          class="flex items-center ml-1 uppercase"
          target="_blank"
        >
          <span class="max-w-[60px] text-ellipsis overflow-hidden inline-block">{{ txHash }}</span>
          <span class="icon-ico-link-external relative text-sm"/>
        </a>
      </div>

      <p v-if="notPermitted || availability?.outcome === 'NotPermitted'" class="mb-3">
        Unfortunately you are unable to {{ walletModalMode === 'custodialWithdrawal' ? 'withdraw' : 'swap' }} at this time.
      </p>
      <p v-if="availability?.outcome === 'VerificationRequired'" class="mb-3 text-red">
        Your account is currently under review. Please contact <a class="underline hover:text-white" href="mailto:support@metawin.com">support</a> for more information.
      </p>
      <p v-if="availability?.outcome === 'MinimumDepositlNotMet'" class="mb-3 text-red">
        {{ availability?.detail }}
      </p>
      <p v-if="error && swapAmount" class="text-red mb-2">
        {{ error }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapState, mapWritableState, mapActions } from 'pinia';
import BigNumber from 'bignumber.js';
import { useBankingStore } from '@/store/banking';
import { useUserStore } from '@/store/user';
import { useAuthStore } from '@/store/auth';
import { useUiStore } from '@/store/ui';
import { useCryptoStore } from '@/store/crypto';
import { useWebsocketStore } from '@/store/websocket';
import { useBlockchainConfigStore } from '@/store/blockchainConfig';

export default defineComponent({
  name: 'ModalWalletFormWithdraw',
  props: {
    currency: {
      type: String,
      default: null,
    },
    network: {
      type: String,
      default: null,
    },
    swapAmount: {
      type: [String, Number,],
      default: null,
    },
    submitTrigger: {
      type: Object,
      default: null,
    },
    locationRestricted: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'onProcessing',
    'onLoadingData',
    'onBlockSubmitBtn',
    'onWithdrawalAvailabilityChange',
    'close',
    'onComplete',
  ],
  setup() {
    const websocketStore = useWebsocketStore();
    return { websocketStore, };
  },
  data() {
    return {
      ready: false,
      withdrawalLoading: false,
      error: null,
      availability: null,
      meta: null,
      showWithdrawalCompleted: false,
      withdrawalProcessingId: null,
      withdrawalProcessing: false,
      pendingWithdrawal: null,
      notPermitted: false,
      requestAmount: undefined,
      feeInitialValue: 0.005,
      txHash: null,
    };
  },
  computed: {
    ...mapWritableState(useBankingStore, ['swapsPending',]),
    ...mapWritableState(useUiStore, ['showInfoModal', 'infoModalType', 'infoModalContent', 'showTxToastNotification', 'walletModalMode', 'walletModalFundsContext',]),
    ...mapState(useAuthStore, ['address', 'isUserLogged',]),
    ...mapState(useCryptoStore, ['exchangeRateEthUsd',]),
    ...mapState(useUserStore, ['userData', 'userFeatures',]),
    availableAmount() {
      if (!this.availability?.availableAmount) { return 0; }
      return new BigNumber(this.availability.availableAmount);
    },
    hasFee() {
      return !!this.availability?.fee && this.availability.fee?.amount > 0;
    },
    percentageFee() {
      return this.availability?.fee && this.availability.fee.configuration.type === 'Percentage';
    },
    flatFee() {
      return this.availability?.fee && this.availability.fee.configuration.type === 'Flat';
    },
    withdrawalFeeAmount() {
      if (!this.availability?.fee) { return 0; }
      return new BigNumber(this.availability.fee.amount);
    },
    defaultAmounts() {
      if (!this.availability.defaultAmounts) { return []; }
      return this.availability.defaultAmounts;
    },
    isWithdraw() {
      return this.walletModalMode === 'custodialWithdrawal';
    },
    requireConfirm() {
      if (!this.availability?.requireConfirm) { return null; }
      return this.availability.requireConfirm;
    },
    withdrawalLimit() {
      if (!this.availability?.limit) { return null; }
      return this.availability?.limit;
    },
    withdrawalLimitTime() {
      const limitHours = this.availability.limitPeriodInHours;

      if (!limitHours) { return; }

      return `${limitHours} hour period`;
    },
    hasRemainingWithdrawalLimit() {
      if (!this.availability?.limitRemaining) { return false; }
      return !new BigNumber(this.availability?.limitRemaining).isZero();
    },
    txUrl() {
      if (!this.txHash) { return null; }
      return this.getExplorerUrl(this.network, 'transaction', this.txHash);
    },
  },
  watch: {
    withdrawalProcessing(val) {
      this.$emit('onProcessing', this.currency, this.walletModalFundsContext, val);
    },
    submitTrigger: {
      handler(val) {
        this.onSubmit(val.amount, val.paymentMethod, val.context, this.network, val.tag);
      },
      deep: true,
    },
    swapAmount(val) {
      this.setRequestValue(val);
    },
    swapsPending() {
      this.checkPendingWithdrawal();
    },
    async currency() {
      await this.init();
    },
  },
  async created() {
    await this.init();

    this.websocketStore.$onAction(({ name, args, }) => {
      if (name === 'handleMessage') {
        if (args[0].type === 'Withdrawal:StatusChange') { useMessageHandler({ name, args, }, this.withdrawalUpdate, 'Withdrawal:StatusChange'); }
      }
    });
  },
  methods: {
    ...mapActions(useBankingStore, ['requestWithdrawal', 'withdrawalAvailability',]),
    ...mapActions(useBlockchainConfigStore, ['getExplorerUrl',]),
    async init() {
      this.$emit('onLoadingData', true);

      this.requestAmount = null;
      this.pendingWithdrawal = null;

      if (!this.userFeatures?.withdraw) {
        this.notPermitted = true;
        this.ready = true;
        return;
      }

      if (this.locationRestricted) {
        this.ready = true;
        this.$emit('onLoadingData', false);
        return;
      }

      this.checkPendingWithdrawal();

      await Promise.allSettled([this.loadWithdrawalAvailability(), this.loadPendingWithdrawals(),]);
      this.ready = true;
      this.setRequestValue(this.swapAmount);
      this.$emit('onLoadingData', false);
    },
    async loadWithdrawalAvailability() {
      if (this.withdrawalLoading) { return; }

      this.error = null;
      this.withdrawalLoading = true;
      this.$emit('onBlockSubmitBtn', true);
      try {
        const availability = await this.withdrawalAvailability(this.currency, this.requestAmount ? this.requestAmount.toString() : undefined, this.walletModalFundsContext);
        this.availability = availability;
        this.$emit('onWithdrawalAvailabilityChange', availability);
      } catch (err) {
        this.error = err.message;
      } finally {
        this.$emit('onBlockSubmitBtn', false);
        this.withdrawalLoading = false;
      }
    },
    async loadPendingWithdrawals() {
      try {
        const data = await this.$api('/withdrawal', {
          method: 'GET',
          params: {
            page: 1,
            pageSize: 1,
            pending: true,
            currencyCode: this.currency,
            fundsContext: this.walletModalFundsContext,
          },
        });
        if (data?.items?.length) {
          this.pendingWithdrawal = data.items[0];
          this.txHash = data.items[0]?.providerRef;
          this.withdrawalProcessing = true;
        }
      } catch (err) {
        useNuxtApp().$rollbar.error('method failed', err);
      }
    },
    setRequestValue(amount) {
      if (!(amount !== '' && amount !== 0)) {
        this.$emit('onBlockSubmitBtn', true);
      } else {
        this.$emit('onBlockSubmitBtn', false);
      }
      const newRequestAmount = new BigNumber(amount);

      this.requestAmount = newRequestAmount;
    },
    async resetRequestValue() {
      this.requestAmount = undefined;
      await this.loadWithdrawalAvailability();
    },
    async onSubmit(amount, paymentMethod, context, network, tag) {
      this.error = null;
      try {
        this.withdrawalProcessing = true;
        this.$emit('onBlockSubmitBtn', true);
        const result = await this.requestWithdrawal(this.currency, amount.toString(), paymentMethod, context, network, tag);
        if (result.status === 'Pending' && this.requireConfirm) {
          this.showTxToastNotification('RequireConfirm', 'Withdrawal', context);
        }

        this.$emit('close');
      } catch (err) {
        if (err.message !== 'Withdrawal unavailable.') { this.error = err.message; }
        this.withdrawalProcessing = false;
      } finally {
        this.$emit('onBlockSubmitBtn', false);
      }
    },
    async withdrawalUpdate(payload) {
      if (['Confirmed', 'Approved',].includes(payload.status)) {
        this.$emit('close');
      } else if (['Cancelled', 'Declined', 'Error', 'InsufficientFunds',].includes(payload.status)) {
        this.withdrawalProcessing = false;
        this.$emit('onProcessing', payload.currencyCode, payload.fundsContext, false);
      } else if (payload.status === 'Complete') {
        this.withdrawalProcessing = false;
        this.showWithdrawalCompleted = true;
        this.$emit('onProcessing', payload.currencyCode, payload.fundsContext, false);
        this.$emit('onComplete', payload.currencyCode, payload.fundsContext, true);
        await this.resetRequestValue();
      }

      this.txHash = payload?.providerRef;
      this.requested = false;
    },
    checkPendingWithdrawal() {
      const pendingWithdrawal = this.swapsPending.find(swap => swap.direction === 'out' && swap.currencyCode === this.currency);
      this.withdrawalProcessing = !!pendingWithdrawal;
    },
  },
});
</script>
