<template>
  <div v-if="!buyOnly" class="flex items-center justify-center">
    <div v-if="address && dataUrl" class="animate-fade-in">
      <VueQrcode
        :value="dataUrl"
        type="image/png"
        :color="{ dark: '#1475E1', light: '#25283100' }"
        class="h-[184px] rounded-md"
      />
    </div>
    <div v-else-if="locationRestricted" class="h-[184px] flex items-center justify-center font-bold text-sm rounded-lg bg-black/50 px-5 text-center w-full mb-3">
      Sorry, deposits are not available in your location.
    </div>
    <div v-else class="h-[184px] flex items-center justify-center">
      <Spinner :has-logo="false" size="xs"/>
    </div>
  </div>
  <template v-if="!locationRestricted">
    <div class="text-xs font-bold text-center mt-2">
      Your unique {{ currencyBaseCode || currency }} deposit address
    </div>
    <div class="text-blue-500 text-xs text-center mb-5 font-bold">
      {{ address || '&nbsp;' }}
    </div>
    <div class="text-xs text-center font-bold">
      <div class="text-orange-500">
        Send <span class="font-bold text-orange-200">{{ currencyBaseCode || currency }}</span> <span class="underline text-red-600">ONLY</span> on the <span class="font-bold text-orange-200">{{ networkName }}</span> network to this address or your funds may be lost.
      </div>
      <div v-if="minDeposit" class="text-gray-400 mt-2">
        Deposits less than <span class="font-bold text-gray-200">{{ minDeposit * currencyFactor }} {{ currencyBaseCode || currency }}</span> will <span class="underline text-red-600">NOT</span> be credited to your balance.
      </div>
      <div v-if="currencyFactor !== 1" class="text-gray-400 mt-2">
        <span class="font-bold text-gray-200">1 {{ currency }}</span> will be credited for every <span class="font-bold text-gray-200">{{ currencyFactor }} {{ currencyBaseCode || currency }}</span> deposited.
      </div>
      <p v-if="currency === 'BTC'" class="mt-2 font-bold text-orange-500">
        Bitcoin deposits can take some time to process on the blockchain. You can check its status on your
        <NuxtLink to="/wallet?view=Transactions" class="text-white">
          transactions
        </NuxtLink>
        page.
      </p>
    </div>
    <ButtonButton
      v-if="!buyOnly"
      class="w-full mt-5"
      :class="{ 'my-5': !moonpayEnabled }"
      :disabled="!ready"
      @click.prevent.stop="copyAddress"
    >
      {{ copyAddressText ?? `Copy ${currencyBaseCode || currency} Address` }}
    </ButtonButton>
    <ButtonButton
      v-if="moonpayEnabled && currencyFactor === 1"
      :theme="buyOnly ? 'primary' : 'grey'"
      class="w-full my-5"
      :disabled="!ready || moonpayLoading"
      @click.prevent="$emit('start-moonpay-payment')"
    >
      Buy {{ currency }} with
      <img src="~/assets/img/logos/moonpay.svg" class="inline-block w-24 relative -top-[2px] ml-1" alt="Moonpay">
    </ButtonButton>
  </template>
</template>

<script>
import VueQrcode from 'vue-qrcode';
import { mapActions } from 'pinia';
import { useBlockchainConfigStore } from '@/store/blockchainConfig';

export default defineComponent({
  name: 'ModalWalletProcessQrDeposit',
  components: {
    VueQrcode,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
    currency: {
      type: String,
      required: true,
    },
    currencyBaseCode: {
      type: String,
      default: null,
    },
    currencyFactor: {
      type: Number,
      default: 1,
    },
    network: {
      type: String,
      default: null,
    },
    minDeposit: {
      type: String,
      default: null,
    },
    dataUrl: {
      type: String,
      default: null,
    },
    address: {
      type: String,
      default: null,
    },
    ready: {
      type: Boolean,
      default: false,
    },
    buyOnly: {
      type: Boolean,
      default: false,
    },
    moonpayEnabled: {
      type: Boolean,
      required: true,
    },
    locationRestricted: {
      type: Boolean,
      required: true,
    },
    moonpayLoading: {
      type: Boolean,
      required: false,
    },
  },
  emits: [
    'start-moonpay-payment',
  ],
  data() {
    return {
      copyAddressText: null,
    };
  },
  computed: {
    networkName() {
      return this.getNetworkName(this.network);
    },
  },
  methods: {
    ...mapActions(useBlockchainConfigStore, ['getNetworkName',]),
    copyAddress() {
      this.$copyText(this.address, undefined, (error) => {
        if (error) {
          this.copyAddressText = 'Copy Failed';
        } else {
          this.copyAddressText = 'Copied!';
          setTimeout(() => {
            this.copyAddressText = null;
          }, 2000);
        }
      });
    },
  },
});
</script>
