<template>
  <div class="container">
    <div class="relative cursor-pointer" @click.prevent.stop="toggle">
      <div
        v-if="!toggleBlocked"
        class="absolute z-20 w-[34px] h-[34px] rounded-full bg-blue lg:hover:bg-blue-400 right-4 flex items-center justify-center top-1/2 -mt-4 transition-all duration-300 animate-slide-down-fade-in01s"
        :class="{ 'rotate-180': arrowsRotated }"
      >
        <span class="icon-ico-sort-arrows text-lg"/>
      </div>

      <transition-group name="list-position">
        <ModalWalletToggleButton
          v-for="(button, index) in buttons"
          v-bind="button"
          :key="button.owner"
          :display-index="index"
          :toggle-blocked="toggleBlocked"
          :currency="currency"
          :currency-factor="currencyFactor"
          :currency-base-code="currencyBaseCode"
        />
      </transition-group>
    </div>

    <p
      v-if="walletModalMode === 'withdraw'"
      class="text-slate-100 text-xs lg:text-sm text-center mt-5"
    >
      <span class="text-pink-400 uppercase tracking-wider">Zero friction guarantee</span>
      <template v-if="maxLimit">
        <br>Swaps up to {{ maxLimit }} {{ currency }} per 24h period are instant.
      </template>
      <span v-else class="flex justify-center">
        <Spinner :has-logo="false" size="xs"/>
      </span>
    </p>
    <div v-else class="text-slate-100 text-xs lg:text-sm text-center mt-5">
      <p class="text-pink-400 font-bold uppercase tracking-wider">Winning in Web3</p>
      <p class="text-slate-100 tracking-wider">Swaps are instant.</p>
      <p class="text-slate-100 tracking-wider">You'll have your funds in a few seconds.</p>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  buttons: {
    type: Array,
    default: null,
  },
  toggleBlocked: {
    type: Boolean,
    default: false,
  },
  walletModalMode: {
    type: String,
    default: null,
  },
  maxLimit: {
    type: [Number, String,],
    default: null,
  },
  currency: {
    type: String,
    required: true,
  },
  currencyFactor: {
    type: Number,
    default: 1,
  },
  currencyBaseCode: {
    type: String,
    default: null,
  },
});

const emits = defineEmits([
  'toggle',
]);

const arrowsRotated = computed(() => props.buttons[0].owner === 'client');

function toggle() {
  if (!props.toggleBlocked) {
    emits('toggle');
  }
}
</script>
