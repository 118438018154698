<template>
  <ModalMainNew transition-name="modal-slide-up" media-type="tx" @close="close">
    <div
      v-if="canPop && wallet"
      class="flex absolute cursor-pointer justify-center items-center top-2 left-2 w-8 h-8 bg-slate-600 rounded-full transition-all hover:bg-slate-500 animate-slide-down-fade-in02s"
      @click.prevent.stop="toSwap()"
    >
      <img class="rotate-90" src="~/assets/img/icons-figma-export/ico-arrow-down.svg">
    </div>

    <header
      class="modal-header-tabs animate-fade-in text-center pb-0 bg-bottom flex pt-4 w-full z-40 bg-slate-500 rounded-t-xl fixed"
    >
      <div
        v-for="tab in activeTabs"
        :key="tab.name"
        class="tab relative flex-1 text-slate-400 pt-2"
        :class="[
          activeTab === tab
            ? `${tab.radial} text-white`
            : null,
          activeTabs.length > 1 ? 'cursor-pointer' : null
        ]"
        @click.prevent.stop="changeTab(tab.name)"
      >
        <div class="pb-4 flex items-center justify-center text-center">
          <h1 class="font-bold text-xl">{{ tab.name }}</h1>
        </div>
        <div
          v-if="activeTab === tab"
          class="h-[2px] w-full absolute left-0 z-50"
          :class="[activeTab === tabs[0] ? 'bg-blue-500' : `${tab.border}`]"
        />
      </div>
    </header>
    <div class="flex flex-col px-4 md:px-7 w-full pt-[22px] h-inherit bg-slate-500 relative scroll-height mt-[68px]">
      <div class="flex flex-col-reverse" :class="showWeb3Connect">
        <p :class="showWeb3Connect" class="text-slate-400 text-center pb-3 pt-1">or</p>
        <div class="flex-order-2">
          <ModalWalletWeb3Wallet
            :web-data="web3Providers"
            :providers="providers"
            :is-loading-providers="isLoadingProviders"
            :wallet="wallet"
            web3-only
            hide-connect
            @switch-to-swap-tab="handleSwitchToSwapTab"
            @on-login="login"
          />
        </div>
      </div>
      <div class="flex-order-2">
        <div class="animate-fade-in text-center px-2 mb-4">
        <h4 class="font-bold text-sm lg:text-base mx-auto mb-[10px]">
          {{ walletModalFundsContext === 'Default' ? 'Web3' : 'Custodial' }} Wallet
        </h4>
        <button
          v-if="activeTab === tabs[0]"
          class="flex text-xs font-bold w-full text-center justify-center"
          @click.prevent="changeTab(tabs[1].name)"
        >
          <p class="text-slate-100">
            For more currencies,
            <span class="text-green-bright">
              &nbsp;switch to your Custodial Wallet.
            </span>
          </p>
        </button>
        <p v-if="activeTab === tabs[1]" class="text-slate-100 text-sm">
          Deposit into your unique custodial wallet using the address below
        </p>
        <div v-else-if="activeTab === tabs[3]" class="text-sm text-slate-100">
          <p>Withdraw from your custodial wallet below.</p>
          <p class="text-orange-500 mt-[12px]">
            Withdrawals from Web3 swaps are not supported here.
          </p>
        </div>
      </div>
      <template v-if="(activeTab === tabs[0] && isWalletConnected) || activeTab !== tabs[0]">
        <div v-if="currencyOptionsList.length > 1" class="dropdown mb-4">
          <DropdownMain
            :options="currencyOptionsList"
            :active-option="activeCurrencyOption"
            theme="input-normal"
            full
            is-tx-modal
            class="z-30"
            @select="setCurrencyOption"
          >
            <div class="flex items-center">
              <SvgIcon
                :icon="activeCurrencyOption.icon"
                width="24"
                height="24"
                class="my-auto"
              />
              <span class="mx-2">
                {{ activeCurrencyOption.text }}
              </span>
              <CurrencyBadge :currency="activeCurrencyOption.badge.name"/>
              <span
                v-if="balance.factor !== 1 && activeCurrencyOption.baseCode"
                v-tippy="{ content: `1 ${activeCurrencyOption.code} = ${activeCurrencyOption.factor} ${activeCurrencyOption.baseCode}` }"
                class="icon-ico-info text-base text-yellow-500 font-bold ml-1"
                />
            </div>
          </DropdownMain>
        </div>
        <div v-if="networkOptionsList.length > 1" class="dropdown mb-4 -mt-1">
          <div class="text-3xs font-bold mb-2 text-slate-100">Choose Network</div>
          <DropdownMain
            :options="networkOptionsList"
            :active-option="activeNetworkOption"
            theme="input-normal"
            full
            is-tx-modal
            class="z-[29]"
            @select="setNetworkOption"
          >
            <div class="flex items-center">
              <SvgIcon
                :icon="activeNetworkOption.icon"
                width="24"
                height="24"
                class="my-auto"
              />
              <span class="mx-2">
                {{ activeNetworkOption.text }}
              </span>
            </div>
          </DropdownMain>
        </div>
        <div v-if="getConfig('ENV_NAME') !== 'Prod'">
          <ButtonButton
            v-if="canBridge"
            theme="grey"
            size="sm"
            class="w-full mb-4 flex items-center justify-center"
            @click="bridge()"
          >
            Bridge {{ currency }} to
            <SvgIcon
              :icon="activeNetwork"
              width="16"
              height="16"
              class="ml-2 mr-1"
            />
            {{ activeNetworkOption.text }}
          </ButtonButton>
        </div>
      </template>
      <div class="relative">
        <div
          v-if="depositRequired || emailVerificationRequired"
          class="absolute top-0 left-0 right-0 bottom-0 rounded-md bg-slate-900/90 backdrop-blur-sm font-bold z-20 flex flex-col justify-center items-center text-center px-6 text-sm"
        >
          <h4 v-if="depositRequired" class="text-xl mb-2">Deposit Required</h4>
          <h4 v-if="emailVerificationRequired" class="text-xl mb-2">
            Email Verification Required
          </h4>
          <p class="mb-4">{{ formInterface.detail }}</p>
          <ButtonButton
            v-if="depositRequired"
            theme="primary"
            @click.prevent.stop="changeTab(tabs[1].name)"
          >
            Deposit Now
          </ButtonButton>
          <ButtonButton
            v-if="emailVerificationRequired"
            theme="primary"
            @click.prevent.stop="toSettings()"
          >
            Verify Now
          </ButtonButton>
        </div>
        <div
          v-if="!isCorrectNetwork && !isCustodialWithdrawalMode && activeTab === tabs[0] && isWalletConnected"
          class="absolute z-[28] top-0 right-0 bottom-0 left-0 bg-slate-900/90 backdrop-blur-sm rounded-md flex flex-col items-center justify-center text-sm font-bold"
        >
          <div>
            Switch network on your wallet to continue
          </div>
          <ButtonButton
            theme="google"
            size="sm"
            class="mt-4"
            @click="switchNetwork"
          >
            <SvgIcon
              :icon="activeNetwork"
              width="16"
              height="16"
              class="mr-1"
            />
            Switch to {{ activeNetworkOption.text }}
          </ButtonButton>
        </div>
        <ModalWalletToggle
          v-if="!isCustodialWithdrawalMode && activeTab === tabs[0] && isWalletConnected"
          :buttons="buttons"
          :toggle-blocked="isToggleBlocked || walletModalData?.toggleBlocked"
          :wallet-modal-mode="walletModalMode"
          :max-limit="maxLimit"
          :currency="currency"
          :currency-factor="activeCurrencyOption?.factor"
          class="mb-5"
          @toggle="toggle"
        />
        <ModalWalletFormInterface
          v-if="(activeTab === tabs[0] && isWalletConnected) || activeTab === tabs[3]"
          v-bind="formInterface"
          :currency-factor="activeCurrencyOption?.factor"
          :currency-base-code="activeCurrencyOption?.baseCode"
          :wallet-modal-mode="walletModalMode"
          :wallet-modal-funds-context="walletModalFundsContext"
          :is-processing-tx="isToggleBlocked"
          :is-loading-data="isLoadingData"
          :is-submit-btn-blocked="isSubmitBtnBlocked"
          :is-complete="
            isCompleteMap[
              activeTab === tabs[0] ? 'ETH_Default' : `${currency}_${walletModalFundsContext}`
            ] || false
          "
          :client-wallet-balance="clientWalletBalance"
          :platform-balance="platformBalance"
          show-exchange-rate
          :currency="currency"
          :fee="formInterface.fee"
          :max-limit="maxLimit"
          :disable-input="formInterface.outcome !== 'Available' && activeTab.name !== 'Swap'"
          @on-submit="submitSwapAmount"
          @on-swap-amount-update="swapAmountUpdate"
          @on-complete="complete"
        />
        <ModalWalletProcessDeposit
          v-if="walletModalMode === 'deposit'"
          :currency="currency"
          :currency-factor="activeCurrencyOption?.factor"
          :network="activeNetwork"
          :swap-amount="parseFloat(swapAmount)"
          :submit-trigger="submitTrigger"
          @close="close"
          @on-processing="processingTx"
          @on-complete="complete"
          @use-qr="custodialDeposit"
        />
        <ModalWalletProcessWithdraw
          v-if="walletModalMode === 'custodialWithdrawal' || walletModalMode === 'withdraw'"
          :currency="currency"
          :network="activeNetwork"
          :swap-amount="parseFloat(swapAmount)"
          :submit-trigger="submitTrigger"
          :location-restricted="isWithdrawalRestricted"
          @close="close"
          @on-loading-data="loadingData"
          @on-processing="processingTx"
          @on-withdrawal-availability-change="withdrawalAvailabilityChange"
          @on-block-submit-btn="blockSubmitBtn"
          @on-complete="complete"
        />
        <div
          v-if="activeTab === tabs[0] && !isWalletConnected"
          class="isWalletConnected text-center"
        >
          <p
            class="w-full text-center text-slate-100 text-sm font-light max-w-[320px] mx-auto px-5 mb-2"
          >
            To swap funds you need to connect your Web3 wallet.
          </p>
          <ButtonConnect
            v-if="userHasAddress"
            size="sm"
            class="mt-4 mb-28"
            web3-only
            no-login
            :chain-type="userAliasType"
            swap
          />
          <ModalConnectWallet
            :web-data="web3Providers"
            :providers="providers"
            :is-loading="isLoadingProviders"
            web3-only
            hide-connect
            @on-login="login"
          />
        </div>
        <ModalWalletProcessQrDeposit
          v-if="activeTab === tabs[1] || activeTab === tabs[2]"
          :data="walletModalData"
          :address="address"
          :min-deposit="currentBalance?.config?.deposit?.minAmount"
          :ready="ready"
          :data-url="dataUrl"
          :currency="currency"
          :currency-base-code="activeCurrencyOption?.baseCode"
          :currency-factor="activeCurrencyOption?.factor"
          :network="activeNetwork"
          :buy-only="activeTab === tabs[2]"
          :moonpay-enabled="MOONPAY_ENABLED"
          :moonpay-loading="isMoonpayLoading"
          :location-restricted="isDepositRestricted"
          @start-moonpay-payment="startMoonpayPayment"
        />
      </div>
      <div
        class="absolute w-full h-6 left-0 bottom-0 bg-gradient-to-t to-transparent from-slate-500 pointer-events-none"
      />
    </div>
    <div class="flex flex-order-4 justify-center">
        <ButtonLink
          theme="loadmore"
          size="sm"
          :to="{ path: '/wallet', query: { view: 'Transactions' } }"
          class="font-semibold text-center my-2 hover:bg-slate-700 rounded-md"
          @click.prevent.stop="close"
        >
        Transaction History
        </ButtonLink>
      </div>
    <div class="sticky order-last w-full h-6 left-0 bottom-0 bg-gradient-to-t to-transparent from-slate-500 pointer-events-none"/>
</div>
</ModalMainNew>
</template>

<script>
import { mapWritableState, mapActions, mapState } from 'pinia';
import BigNumber from 'bignumber.js';
import Web3 from 'web3';

import { useUiStore } from '@/store/ui';
import { useAuthStore } from '@/store/auth';
import { useCryptoStore } from '@/store/crypto';
import { useBankingStore } from '@/store/banking';
import { useDepositAccountStore } from '@/store/depositAccount';
import { useUserStore } from '@/store/user';
import { useDeviceStore } from '@/store/device';
import { useLocationStore } from '@/store/location';
import { useBlockchainConfigStore } from '@/store/blockchainConfig';
import { getConfig } from '@/utils/getConfig';
import { useBodyScroll } from '@/utils/useBodyScroll';
import { getCurrencyBadgeStyle } from '@/utils/getCurrencyBadgeStyle';
import { DEPOSIT_ACCOUNT_PROVIDER } from '@/core/deposit-account/provider';

import metaMaskLogo from '~/assets/img/logos/metamask.svg';
import coinbaseLogo from '~/assets/img/logos/coinbase.png';
import walletConnectLogo from '~/assets/img/logos/walletconnect.svg';
import phantomLogo from '~/assets/img/logos/phantom.svg';

export default defineComponent({
  name: 'ModalWalletMain',
  setup() {
    const MOONPAY_ENABLED = ref(useNuxtApp().$config.public.MOONPAY_ENABLED);
    return {
      MOONPAY_ENABLED,
    };
  },
  data() {
    return {
      poller: null,
      isProcessingTxMap: {},
      isProcessingTx: false,
      isLoadingData: false,
      isSubmitBtnBlocked: false,
      isCompleteMap: {},
      minSwapDefault: 0.005,
      maxSwapDefault: 5,
      activeCurrency: null,
      activeNetwork: null,
      maxLimit: null,
      defaultPresets: [0.05, 0.1, 0.5, 1, 'MAX',],
      formInterface: {
        currency: 'ETH',
        presets: [0.05, 0.1, 0.5, 1, 'MAX',],
        minSwap: 0.005,
        maxSwap: 5,
        showExchangeRate: false,
        limitRemaining: null,
        outcome: null,
        fee: 0,
      },
      canPop: false,
      swapAmount: 0.1,
      ready: false,
      errorMessage: null,
      address: null,
      dataUrl: null,
      submitTrigger: {
        amount: 0,
        trigger: 0,
      },
      withdrawalAvailability: null,
      tokenBalance: '0',
      isMoonpayLoading: false,
      tabs: [
        {
          name: 'Swap',
          mode: 'deposit',
          fundsContext: 'Default',
          loadDepositAccounts: false,
          radial: 'bg-blue-radial',
          border: 'bg-blue-500',
          hidden: true,
        },
        {
          name: 'Deposit',
          mode: 'custodialDeposit',
          fundsContext: 'Custodial',
          loadDepositAccounts: true,
          radial: 'bg-orange-radial',
          border: 'bg-orange-500',
          hidden: false,
        },
        {
          name: 'Buy Crypto',
          mode: 'buyCrypto',
          fundsContext: 'Custodial',
          loadDepositAccounts: true,
          radial: 'bg-orange-radial',
          border: 'bg-orange-500',
          hidden: true,
        },
        {
          name: 'Withdraw',
          mode: 'custodialWithdrawal',
          fundsContext: 'Custodial',
          loadDepositAccounts: false,
          radial: 'bg-orange-radial',
          border: 'bg-orange-500',
          hidden: false,
        },
      ],
      activeTab: null,
      currencyMap: {
        ETH: {
          color: 'text-blue-500',
          bg: 'bg-blue-500/20',
        },
        BTC: {
          color: 'text-orange-500',
          bg: 'bg-orange-500/20',
        },
        SOL: {
          color: 'text-pink-300',
          bg: 'bg-purple-500/20',
        },
        USDT: {
          color: 'text-green-500',
          bg: 'bg-green-500/20',
        },
        USDC: {
          color: 'text-blue-300',
          bg: 'bg-blue-300/20',
        },
        DAI: {
          color: 'text-orange-300',
          bg: 'bg-orange-300/20',
        },
        LTC: {
          color: 'text-gray-200',
          bg: 'bg-gray-200/20',
        },
        DOGE: {
          color: 'text-yellow-500',
          bg: 'bg-yellow-500/20',
        },
        MATIC: {
          color: 'text-purple-500',
          bg: 'bg-purple-500/20',
        },
        BNB: {
          color: 'text-yellow-200',
          bg: 'bg-yellow-200/20',
        },
        BCH: {
          color: 'text-green-200',
          bg: 'bg-green-200/20',
        },
        TRX: {
          color: 'text-red-500',
          bg: 'bg-red-500/20',
        },
        XRP: {
          color: 'text-white',
          bg: 'bg-gray-800',
        },
        EOS: {
          color: 'text-cyan',
          bg: 'bg-blue-500/20',
        },
        SHFL: {
          color: 'text-purple-400',
          bg: 'bg-purple-500/20',
        },
        RLB: {
          color: 'text-yellow-200',
          bg: 'bg-gray-800',
        },
        JCKPT: {
          color: 'text-yellow-300',
          bg: 'bg-red-500',
        },
        EURC: {
          color: 'text-blue-300',
          bg: 'bg-blue-300/20',
        },
      },
      activeCurrencyOption: null,
      activeNetworkOption: null,
      accountsInitialised: false,
      accountsLoading: false,
      providers: {
        metamask: {
          label: 'MetaMask',
          loading: false,
          image: metaMaskLogo,
          type: 'Web3',
          isLarge: true,
          chain: 'Ethereum',
        },
        coinbase: {
          label: 'CoinBase',
          loading: false,
          image: coinbaseLogo,
          type: 'Web3',
          isLarge: false,
          chain: 'Ethereum',
        },
        walletconnect: {
          label: 'WalletConnect',
          loading: false,
          image: walletConnectLogo,
          type: 'Web3',
          isLarge: false,
          chain: 'Ethereum',
        },
        phantom: {
          label: 'Phantom',
          loading: false,
          image: phantomLogo,
          type: 'Web3',
          isLarge: false,
          chain: 'Solana',
        },
      },
      closing: false,
    };
  },
  computed: {
    ...mapWritableState(useUiStore, [
      'showWalletModal',
      'walletModalMode',
      'walletModalCurrency',
      'walletModalFundsContext',
      'walletModalData',
      'toastNotification',
      'showDepositAccountModal',
      'connectNoLogin',
    ]),
    ...mapWritableState(useAuthStore, ['attach', 'connectFromEmail',]),
    ...mapState(useBankingStore, ['allBalances', 'swapsPending', 'balances', 'activeCurrencyOverride',]),
    ...mapState(useUserStore, ['userData', 'userProfile',]),
    ...mapState(useDepositAccountStore, ['accounts',]),
    ...mapState(useAuthStore, ['wallet', 'isUserLogged',]),
    ...mapState(useCryptoStore, ['balance',]),
    ...mapState(useDeviceStore, ['isMobileDevice',]),
    ...mapState(useLocationStore, ['isDepositRestricted', 'isWithdrawalRestricted',]),
    activeTabs() {
      if (this.activeTab?.hidden) { return [this.activeTab,]; }

      return this.tabs?.filter(t => !t.hidden);
    },
    currencyOptionsList() {
      return this.balances
      .filter(balance => (balance.context === this.walletModalFundsContext) && !balance.virtual)
      .filter(balance =>
        this.walletModalFundsContext === 'Custodial'
        || (this.wallet?.network === 'Solana' && Object.keys(balance.config.networks).includes('Solana'))
        || (this.wallet?.network !== 'Solana' && !Object.keys(balance.config.networks).includes('Solana'))
      )
      .map((balance) => {
        return {
          code: balance.name,
          text: balance.label,
          icon: balance.name,
          factor: balance.factor,
          baseCode: balance.baseCode,
          badge: {
            name: balance.name,
            classes: getCurrencyBadgeStyle(balance.name),
          },
        };
      });
    },
    networkOptionsList() {
      if (!this.currentBalance) { return []; }

      return Object.entries(this.currentBalance.config?.networks)
      .filter(([, value,]) => !value.contexts || value.contexts.includes(this.walletModalFundsContext))
      .filter(([key,]) => this.walletModalFundsContext === 'Custodial' || !this.wallet || (key === 'Solana' && this.wallet.network === 'Solana') || (key !== 'Solana' && this.wallet.network !== 'Solana'))
      .map(([key,]) => {
        return {
          name: key,
          text: this.getNetworkName(key),
          icon: key,
        };
      });
    },
    clientWalletName() {
      return this.wallet?.name;
    },
    currency() {
      return this.activeCurrency || this.walletModalCurrency;
    },
    clientWalletBalance() {
      if (this.isCurrentBalanceToken) {
        return this.tokenBalance;
      }

      return this.$truncateNumber(this.balance, 7);
    },
    platformBalanceAccountContext() {
      return this.walletModalFundsContext === 'Custodial'
        ? this.walletModalFundsContext.toLowerCase()
        : 'withdrawable';
    },
    platformBalance() {
      return this.allBalances
        ? this.allBalances[this.currency]?.accounts?.[this.platformBalanceAccountContext] || 0
        : 0;
    },
    titleText() {
      const titleText
        = this.walletModalMode === 'custodialDeposit'
          ? 'Deposit'
          : this.isCustodialWithdrawalMode
            ? 'Withdraw'
            : 'Swap';

      return `${titleText} ${this.currency}`;
    },
    buttons() {
      let buttons = [
        {
          owner: 'client',
          walletName: this.clientWalletName,
          balance: this.clientWalletBalance,
          currency: this.currentBalance?.name,
          custodialWithdrawal: this.isCustodialWithdrawalMode,
        },
        {
          owner: 'platform',
          walletName: 'MetaWin',
          balance: this.platformBalance,
          currency: this.currentBalance?.name,
        },
      ];

      if (['withdraw', 'custodialWithdrawal',].includes(this.walletModalMode)) {
        buttons = buttons.reverse();
      }
      return buttons;
    },
    canBridge() {
      return getConfig('TOKEN_BRIDGE_ENABLED') && !['Solana', 'Ethereum',].includes(this.activeNetwork) && Object.keys(this.currentBalance?.config?.networks).length > 1;
    },
    isToggleBlocked() {
      return this.activeTab === this.tabs[0] && (this.isPendingCurrencySwap || this.isProcessingCurrencyTx);
    },
    isProcessingCurrencyTx() {
      return !!this.isProcessingTxMap[`${this.currency}_${this.walletModalFundsContext}`];
    },
    isPendingCurrencySwap() {
      const pending = this.swapsPending.filter(
        swap => swap.currencyCode === this.currency && swap.context === this.walletModalFundsContext
      );
      return pending.length > 0;
    },
    isCustodialWithdrawalMode() {
      return this.walletModalMode === 'custodialWithdrawal';
    },
    isWalletConnected() {
      return !!this.wallet;
    },
    isCorrectNetwork() {
      if (!this.wallet) { return false; }
      return this.getNetworkFromId(this.wallet?.chainId) === this.activeNetwork;
    },
    depositRequired() {
      return this.formInterface.outcome === 'DepositRequirementNotMet';
    },
    emailVerificationRequired() {
      return this.formInterface.outcome === 'VerifiedEmailRequired';
    },
    userHasAddress() {
      return !!this.userData?.aliases?.EthereumAddress || !!this.userData?.aliases?.SolanaAddress;
    },
    userAliasType() {
      if (!this.userData?.aliases?.EthereumAddress && !this.userData?.aliases?.SolanaAddress) {
        return null;
      }

      return this.userData?.aliases?.SolanaAddress ? 'Solana' : 'Ethereum';
    },
    isLoadingProviders() {
      return Object.values(this.providers).some(v => v.loading);
    },
    web3Providers() {
      return Object.keys(this.providers)
      .filter((v) => {
        return this.userData?.aliases ? (!!this.userData?.aliases?.SolanaAddress && this.providers[v].chain === 'Solana') || (!!this.userData?.aliases?.EthereumAddress && this.providers[v].chain === 'Ethereum') : true;
      })
      .filter(v => this.activeProvider[v])
      .filter(v => this.activeProvider[v].type === 'Web3')
      .reduce((obj, key) => {
        obj[key] = this.providers[key];
        return obj;
      }, {});
    },
    activeProvider() {
      const providers = getConfigSplit('AUTH_PROVIDERS');
      return Object.keys(this.providers)
      .filter(v => providers.includes(v))
      .reduce((obj, key) => {
        obj[key] = this.providers[key];
        return obj;
      }, {});
    },
    currentBalance() {
      return this.balances.find(
        item => item.name === this.currency && item.context === this.walletModalFundsContext
      );
    },
    currentBalanceConfig() {
      const network = this.activeNetwork || this.currentBalance?.config?.defaultNetwork;
      return this.currentBalance?.config?.networks?.[network];
    },
    isCurrentBalanceToken() {
      if (!this.currentBalanceConfig) { return false; }
      return this.currentBalanceConfig.assetType === 'Token' && this.currentBalanceConfig?.address;
    },
    showWeb3Connect() {
      if (this.activeCurrencyOverride) {
        return 'hidden';
      }

      const activeTabIndex = this.tabs.findIndex(tab => tab === this.activeTab);
      return activeTabIndex === 1 || activeTabIndex === 3 ? `flex-order-${activeTabIndex}` : 'hidden';
    },
  },
  watch: {
    isUserLogged(val) {
      if (!val) {
        this.close();
      }
    },
  },
  async created() {
    this.setInitialSelectedTab();
    this.setInitialDropdownOption();
    await this.init();
    useBodyScroll(false);
  },
  beforeUnmount() {
    this.showDepositAccountModal = false;
    if (this.poller) {
      clearInterval(this.poller);
    }
    useBodyScroll(true);
  },
  methods: {
    ...mapActions(useCryptoStore, ['walletGet', 'getTokenBalance',]),
    ...mapActions(useDepositAccountStore, ['add', 'getSignedURL',]),
    ...mapActions(useUserStore, ['getUserProfile',]),
    ...mapActions(useAuthStore, ['loginWallet', 'attachWallet',]),
    ...mapActions(useUiStore, ['openConnectModal', 'openAddAliasModal', 'openBridgeModal',]),
    ...mapActions(useBlockchainConfigStore, ['getNetworkName', 'getNetworkFromId',]),
    async init() {
      if (!this.isCustodialWithdrawalMode) {
        await this.updateClientBalance();

        if (
          this.walletModalMode === 'deposit'
          && this.swapsPending[0]?.direction === 'out'
        ) {
          this.toggle();
        }
      }
      this.setFormInterface();

      this.poller = setInterval(async() => {
        await this.updateClientBalance();
      }, 5000);
    },
    async updateClientBalance() {
      if (!this.isWalletConnected || !['deposit', 'withdraw',].includes(this.walletModalMode)) { return; }

      if (this.isCurrentBalanceToken) {
        const network = this.activeNetwork || this.currentBalance?.config?.defaultNetwork;
        this.tokenBalance = await this.getTokenBalance(this.currentBalance.config.networks[network].address, network);
      } else {
        await this.walletGet();
      }

      this.setFormInterface();
    },
    async getOrAdd() {
      if (this.accountsLoading) { return; }

      if (!this.accountsInitialised) {
        const accountStore = useDepositAccountStore();
        this.accountsLoading = true;
        await accountStore.init();
        this.accountsInitialised = true;
        this.accountsLoading = false;
      }

      let account = this.accounts?.find(a => a.currencyCode === this.currency && a.network === this.activeNetwork);

      if (!account) {
        this.ready = false;
        this.dataUrl = null;
        this.address = null;
        account = await this.addAccount(this.currency, this.activeNetwork);
      }

      if (!account) {
        this.ready = true;
        return;
      }

      this.address = account.address;
      this.setDataUrl();
      this.ready = true;
    },
    async addAccount(currencyCode, network) {
      try {
        return await this.add(currencyCode, DEPOSIT_ACCOUNT_PROVIDER.Fireblocks, network);
      } catch (err) {
        switch (err.message) {
          case 'DepositAccountAlreadyExists':
            this.errorMessage
              = 'A deposit wallet for the selected currency already exists.';
            break;
          case 'DepositAccountCreationFailed':
            this.errorMessage = 'We have been unable to create your deposit wallet.';
            break;
          default:
            this.errorMessage = 'An unexpected error occurred.';
            break;
        }
      }
    },
    setDataUrl() {
      switch (this.currency) {
        case 'BTC':
          this.dataUrl = `bitcoin:${this.address}`;
          break;
        case 'WIF':
        case 'SOL':
          this.dataUrl = `solana:${this.address}`;
          break;
        case 'LTC':
          this.dataUrl = `litecoin:${this.address}`;
          break;
        case 'DOGE':
        case 'XRP':
        case 'TRX':
        case 'EOS':
          this.dataUrl = this.address;
          break;
        case 'BCH':
          this.dataUrl = `bitcoincash:${this.address}`;
          break;
        default:
          this.dataUrl = `ethereum:${this.address}?chainId=${Web3.utils.hexToNumber(
            getConfig('ETHEREUM_NETWORK_ID')
          )}`;
          break;
      }
    },
    async startMoonpayPayment() {
      this.isMoonpayLoading = true;
      if (!this.userProfile?.email) {
        await this.getUserProfile();
      }
      try {
        const moonpay = this.$moonpay.init({
          currencyCode: this.currency,
          walletAddress: this.address,
          showWalletAddressForm: true,
          externalTransactionId: this.userData?.id,
          email: this.userProfile?.email ? this.userProfile.email : '',
          theme: 'dark',
          colorCode: '#1475e1',
        });

        const url = moonpay.generateUrlForSigning();
        const response = await this.getSignedURL(url);
        const signature = new URL(response).searchParams.get('signature');

        moonpay.updateSignature(signature);
        moonpay.show();
      } catch (error) {
        this.toastNotification = {
          type: 'error',
          title: 'Failed to initialise Moonpay',
          content: 'Please try again later',
          closeAfter: 5000,
        };
      } finally {
        setTimeout(() => {
          this.isMoonpayLoading = false;
        }, 1000);
      }
    },
    toSwap() {
      this.walletModalMode = 'deposit';
      this.canPop = false;
    },
    toSettings() {
      this.close();
      const router = useRouter();
      return router.push({ path: '/account', });
    },
    custodialDeposit() {
      this.walletModalMode = 'custodialDeposit';
      this.canPop = true;
    },
    close() {
      this.showWalletModal = false;
      this.walletModalMode = 'deposit';
      this.closing = true;
      this.reset();
    },
    async toggle() {
      this.swapAmount = null;
      this.walletModalMode = this.walletModalMode === 'deposit' ? 'withdraw' : 'deposit';
      await this.reset();
      await this.updateClientBalance();
    },
    async reset() {
      await this.setFormInterface();
      this.isCompleteMap = {};
      this.isProcessingTxMap = {};
      this.isLoadingData = false;
      this.isSubmitBtnBlocked = false;
    },
    processingTx(currency, context, val) {
      this.isProcessingTxMap[`${currency}_${context}`] = val;
    },
    swapAmountUpdate(val) {
      if (!(val !== 0 && val !== '')) {
        this.blockSubmitBtn(true);
      } else {
        this.blockSubmitBtn(false);
      }
      this.swapAmount = val;
    },
    submitSwapAmount(val) {
      const newTrigger = this.submitTrigger + 1;

      // This value changes the data props and watchers inside
      //  ModalWalletProcessDeposit & ModalWalletProcessWithdraw process the swap process
      this.submitTrigger = {
        amount: val.amount,
        paymentMethod: val.paymentMethod,
        context: val.context,
        tag: val.tag,
        trigger: newTrigger,
      };
    },
    loadingData(val) {
      this.isLoadingData = val;
    },
    withdrawalAvailabilityChange(val) {
      this.withdrawalAvailability = val;
      this.setFormInterface();
    },
    blockSubmitBtn(val) {
      this.isSubmitBtnBlocked = val;
    },
    async complete(currency, context, val) {
      await this.updateClientBalance();
      this.isCompleteMap[`${currency}_${context}`] = val;
    },
    setFormInterface() {
      if (['deposit', 'custodialDeposit',].includes(this.walletModalMode)) {
        this.formInterface = {
          ...this.formInterface,
          minSwap: this.currentBalance?.config?.deposit?.minAmount || this.minSwapDefault,
          maxSwap: this.clientWalletBalance / (this.activeCurrencyOption?.factor || 1),
          network: this.activeNetwork,
          limitRemaining: null,
          presets: this.defaultPresets,
          outcome: null,
          detail: null,
        };

        const options = this.currentBalance?.config?.deposit?.options;
        if (options?.length > 0) { this.formInterface.presets = [...options, 'MAX',]; }

        this.maxLimit = null;
      } else {
        this.formInterface = {
          ...this.formInterface,
          minSwap: this.withdrawalAvailability?.minimumAmount || this.minSwapDefault,
          maxSwap: this.withdrawalAvailability?.availableAmount || this.maxSwapDefault,
          limitRemaining: this.withdrawalAvailability?.limitRemaining,
          outcome: this.withdrawalAvailability?.outcome,
          detail: this.withdrawalAvailability?.detail,
          network: this.activeNetwork,
        };
        if (this.withdrawalAvailability?.limit) {
          this.maxLimit = this.withdrawalAvailability.limit;
        }

        if (this.withdrawalAvailability?.limitRemaining) {
          this.formInterface.maxSwap = Math.min(
            this.withdrawalAvailability?.limitRemaining,
            this.platformBalance
          );
        }

        if (this.withdrawalAvailability?.limitRemaining) {
          this.formInterface.maxSwap = Math.min(
            this.withdrawalAvailability?.limitRemaining,
            this.platformBalance
          );
        }

        if (this.withdrawalAvailability?.defaultAmounts) {
          this.formInterface.presets = [...this.withdrawalAvailability.defaultAmounts, 'MAX',];
        }

        this.formInterface.fee = this.withdrawalAvailability?.fee ? new BigNumber(this.withdrawalAvailability.fee.amount) : 0;
      }
    },
    async changeTab(name) {
      const tab = this.tabs.find(tab => tab.name === name);

      if (!tab) {
        return;
      }

      this.activeTab = tab;

      this.formInterface.outcome = null;
      this.walletModalFundsContext = tab.fundsContext;
      this.walletModalMode = tab.mode;

      if (tab.name === 'Swap' || tab.name === 'Deposit') {
        const currencyOption = this.currencyOptionsList.find(o => o.code === this.currency) ?? this.currencyOptionsList[0];
        this.setCurrencyOption(currencyOption);
      }

      if (tab.loadDepositAccounts && !this.isDepositRestricted) {
        await this.getOrAdd();
      }
    },
    async setCurrencyOption(option) {
      if (this.activeCurrencyOption === option) {
        return;
      }

      this.activeCurrencyOption = option;
      this.activeCurrency = option.code;
      this.formInterface.outcome = null;

      if (!this.currentBalance?.config?.networks?.[this.activeNetwork]) {
        await this.setNetworkOption(this.networkOptionsList[0]);
      }
      await this.updateClientBalance();
    },
    async setNetworkOption(option) {
      if (option.name === this.activeNetwork) {
        return;
      }

      this.activeNetworkOption = option;
      this.activeNetwork = option.name;
      await this.updateClientBalance();

      if (['deposit', 'withdraw', 'custodialWithdrawal'].includes(this.walletModalMode)) {
        this.setFormInterface();
      }

      if (this.activeTab.loadDepositAccounts && !this.isDepositRestricted) {
        await this.getOrAdd();
      }
    },
    setInitialDropdownOption() {
      let currencyOption = this.currencyOptionsList.find(
        option => option.code === this.currency);

      if (!currencyOption) {
        currencyOption = this.currencyOptionsList[0];
      }

      this.activeCurrencyOption = currencyOption;
      this.activeCurrency = currencyOption.code;
      const chain = this.wallet?.chainId;
      const network = this.getNetworkFromId(chain);
      this.activeNetworkOption = this.networkOptionsList.find(o => o.name === network) ?? this.networkOptionsList[0];
      this.activeNetwork = this.activeNetworkOption?.name;
    },
    setInitialSelectedTab() {
      switch (this.walletModalMode) {
        case 'custodialWithdrawal': {
          this.changeTab(this.tabs[3].name);
          break;
        }
        case 'custodialDeposit': {
          this.changeTab(this.tabs[1].name);
          break;
        }
        case 'buyCrypto': {
          this.changeTab(this.tabs[2].name);
          break;
        }
        default: {
          this.changeTab(this.tabs[0].name);
          break;
        }
      }
    },
    showAddAliasModal(step = 1) {
      this.close();
      const aliasType = this.wallet?.network === 'Solana' ? 'SolanaAddress' : 'EthereumAddress';
      this.openAddAliasModal(aliasType, step, 'swap');
    },
    handleSwitchToSwapTab() {
      this.activeTab = this.tabs[0];
      this.changeTab(this.activeTab.name);
    },
    handleLaunchConnectModal() {
      this.openConnectModal({
        web3Only: true,
        noLogin: false,
        fromEmail: !this.userHasAddress,
        chainType: this.userAliasType,
        message: 'Experience winning in Web3 by connecting your wallet',
      });

      if (!this.userHasAddress) {
        this.close();
      } else {
        this.changeTab(this.tabs[0].name);
      }
    },
    async login(provider) {
      this.connectFromEmail = true;

      this.providers[provider].loading = true;

      if (this.attach) {
        await this.attachWallet(provider, this.connectNoLogin);
      } else {
        await this.loginWallet(provider, this.connectNoLogin, false);
      }

      if (this.wallet && this.connectFromEmail && !this.userHasAddress) {
        this.showAddAliasModal(2);
        return;
      }

      this.changeTab(this.tabs[0].name);
    },
    async switchNetwork() {
      await this.wallet?.checkChain(this.activeNetwork);
      await this.updateClientBalance();
    },
    bridge() {
      this.openBridgeModal({
        fromCurrency: this.currency,
        toCurrency: this.currency,
        fromNetwork: 'Ethereum',
        toNetwork: this.activeNetwork,
      });
    },
  },
});
</script>
<style lang="scss" scoped>
.modal-header-tabs {
  box-shadow: 0 4px 20px -10px black;
  max-width: inherit;
}

.scoll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}

.flex-order-1 {
  order: 1;
}

.flex-order-2 {
  order: 2;
}

.flex-order-3 {
  order: 3;
}

.flex-order-4 {
  order: 4;
}
</style>
