<template>
  <button
    class="p-2 w-full bg-slate-600 rounded-xl flex items-center mb-2"
    :disabled="toggleBlocked"
    :class="{'cursor-default': toggleBlocked}"
  >
    <figure class="w-12 h-12 bg-blue-800 rounded-full flex justify-center items-center overflow-hidden shrink-0 mr-3">
      <img
        v-if="walletNameLowcase === 'metawin'"
        src="~/assets/img/logos/MetaWin-icon-white-cyan.png"
        alt="MetaWin icon"
        width="38"
        height="38"
      >

      <img
        v-else-if="walletNameLowcase === 'metamask'"
        src="~/assets/img/logos/metamask.svg"
        alt="MetaMask icon"
        width="30"
        height="30"
      >

      <img
        v-else-if="walletNameLowcase === 'coinbase'"
        src="~/assets/img/logos/coinbase.svg"
        alt="Coinbase icon"
        width="30"
        height="30"
      >

      <img
        v-else-if="walletNameLowcase === 'phantom'"
        src="~/assets/img/logos/phantom.svg"
        alt="Phantom icon"
        width="30"
        height="30"
      >

      <img
        v-else
        src="~/assets/img/logos/walletconnect.svg"
        alt="WalletConnect icon"
        width="30"
        height="30"
      >
      <span v-if="custodialWithdrawal" class="icon-ico-wallet-solid text-xl font-bold"/>
    </figure>

    <div class="text-left overflow-hidden">
      <h3 class="font-bold text-xl whitespace-nowrap leading-none animate-fade-in">
        <span>{{ nameAppendix }}</span> <span class="text-blue-500">{{ walletNameDisplay }}</span>
      </h3>
      <h4 class="text-xs mt-1 font-semibold text-slate-100 animate-fade-in">
        <span v-if="custodialWithdrawal"/>
        <span v-else>
          Balance <span class="text-[#00E701]">{{ balanceDisplay }} {{ currency }}</span>
        </span>
      </h4>
    </div>
  </button>
</template>

<script setup>
import BigNumber from 'bignumber.js';

const props = defineProps({
  walletName: {
    type: String,
    default: null,
  },
  balance: {
    type: [String, Number,],
    default: null,
  },
  currency: {
    type: String,
    default: null,
  },
  currencyFactor: {
    type: Number,
    default: 1,
  },
  currencyBaseCode: {
    type: String,
    default: null,
  },
  displayIndex: {
    type: Number,
    default: null,
  },
  toggleBlocked: Boolean,
  custodialWithdrawal: Boolean,
});
const nameAppendix = computed(() => props.displayIndex === 0 ? 'From' : 'To');
const balanceDisplay = computed(() => walletNameLowcase.value === 'metawin' ? props.balance : new BigNumber(props.balance).dividedBy(props.currencyFactor));
const walletNameDisplay = computed(() => props.custodialWithdrawal ? 'Wallet Address' : (props.walletName || ''));
const walletNameLowcase = computed(() => props.walletName?.toLowerCase() || '');
</script>
