<template>
  <div v-if="txUrl || !depositAvailable" class="text-center text-sm leading-tight min-h-[16px]">
    <p v-if="!depositAvailable">
      The country you’re connecting from is restricted
    </p>
    <div
      v-else-if="!showDepositCompleted && (txHash || swapsPending.length > 0)"
      class="animate-pulse flex justify-center items-center"
    >
      <span class="inline-block">Pending Swap</span>
      <a
        v-if="txUrl"
        :href="txUrl"
        class="flex items-center ml-1 uppercase"
        target="_blank"
      >
        <span class="max-w-[60px] text-ellipsis overflow-hidden inline-block">{{ txHash }}</span>
        <span class="icon-ico-link-external relative text-sm"/>
      </a>
    </div>
  </div>
</template>

<script>
import { mapWritableState, mapActions, mapState } from 'pinia';
import BigNumber from 'bignumber.js';

import { useUiStore } from '@/store/ui';
import { useCryptoStore } from '@/store/crypto';
import { useBankingStore } from '@/store/banking';
import { useUserStore } from '@/store/user';
import { useBlockchainConfigStore } from '@/store/blockchainConfig';

export default defineComponent({
  name: 'ModalWalletFormDeposit',
  props: {
    swapAmount: {
      type: Number,
      default: null,
    },
    submitTrigger: {
      type: Object,
      default: null,
    },
    currency: {
      type: String,
      default: null,
    },
    currencyFactor: {
      type: Number,
      default: 1,
    },
    network: {
      type: String,
      default: null,
    },
  },
  emits: ['onProcessing', 'onComplete', 'close',],
  data() {
    return {
      isLoading: false,
      txHash: null,
    };
  },
  computed: {
    ...mapWritableState(useUiStore, ['showDepositAccountModal', 'walletModalFundsContext', 'showTxToastNotification',]),
    ...mapWritableState(useBankingStore, ['showDepositCompleted', 'swapsPending', 'balances',]),
    ...mapState(useCryptoStore, ['exchangeRateEthUsd',]),
    ...mapState(useUserStore, ['userFeatures',]),
    depositAmount() {
      return this.swapAmount || 0;
    },
    depositAvailable() {
      return this.userFeatures?.deposit || false;
    },
    txUrl() {
      return this.txHash ? this.getExplorerUrl(this.network, 'transaction', this.txHash) : null;
    },
  },
  watch: {
    isLoading(val) {
      this.$emit('onProcessing', this.currency, this.walletModalFundsContext, val);
    },
    showDepositCompleted(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.isLoading = false;
        this.$emit('onComplete', this.walletModalFundsContext, this.currency, true);
      }
    },
    submitTrigger: {
      handler(val) {
        this.onSubmit(val.amount, val.context);
      },
      deep: true,
    },
    swapsPending() {
      this.checkPendingDeposit();
    },
  },
  mounted() {
    this.checkPendingDeposit();
  },
  beforeUnmount() {
    this.showDepositCompleted = false;
    this.showDepositAccountModal = false;
  },
  methods: {
    ...mapActions(useCryptoStore, ['startTransaction', 'depositTokenAmount',]),
    ...mapActions(useBlockchainConfigStore, ['getExplorerUrl',]),
    async onSubmit(amount, context) {
      if (!this.depositAvailable) { return; }

      const balance = this.balances.find(b => b.name === this.currency && b.context === context);
      const network = this.network || balance.config?.defaultNetwork;
      const networkConfig = balance.config?.networks?.[network];
      const depositAmount = new BigNumber(amount).multipliedBy(this.currencyFactor).toString();

      this.isLoading = true;
      try {
        if (networkConfig.assetType === 'Token' && networkConfig?.address) {
          this.txHash = await this.depositTokenAmount(networkConfig.address, depositAmount, network);
        } else {
          this.txHash = await this.startTransaction(depositAmount, network);
        }

        this.swapsPending.unshift({ txHash: this.txHash, currencyCode: this.currency, direction: 'in', fundsContext: context, });
        if (this.txHash) {
          setTimeout(() => {
            this.showTxToastNotification('Pending', 'Deposit', context);
            this.$emit('close');
          }, 1500);
        }
      } catch (err) {
        this.isLoading = false;
        if (err.code === 4001) {
          useNuxtApp().$rollbar.error('Deposit Failed', err);
        } else {
          useNuxtApp().$rollbar.error('Method failed', err);
        }
      }
    },
    checkPendingDeposit() {
      const pendingDeposit = this.swapsPending.find(swap => swap.direction === 'in' && swap.currencyCode === this.currency && swap.fundsContext === this.walletModalFundsContext);
      this.txHash = pendingDeposit?.txHash || null;
      this.isLoading = !!this.txHash;
    },
  },
});
</script>
