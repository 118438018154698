<template>
  <VForm
    v-slot="{ values, meta }"
    ref="SwapForm"
    :validation-schema="validationSchema"
    class="relative transition-all mb-3"
    :class="{'after:block after:w-full after:h-full after:absolute after:top-0 after:left-0' : isLoadingData}"
  >
    <template v-if="isCustodialWithdrawalMode">
      <div v-if="!paymentMethodOptionsLoading && !isLoadingData" class="text-center relative" :class="{ 'opacity-40': formDisabled }">
        <DropdownMain
          v-if="paymentMethodOptions.length > 0 && !custodialWithdrawalAddress"
          :options="paymentMethodOptions"
          :active-option="paymentMethodOptionQuery"
          show-on-left
          full
          clearable
          :disabled="formDisabled"
          theme="input-normal"
          class="w-full text-left mb-2"
          @select="onPaymentMethodOptionSelected"
        >
          <span class="text-gray-300">{{ paymentMethodOptionQuery.text }}</span>
        </DropdownMain>
      </div>
      <template v-else>
        <div class="flex justify-center">
          <Spinner :has-logo="false" size="xs"/>
        </div>
      </template>
      <InputValidated
        v-if="!paymentMethodRef"
        :class="{ 'opacity-40': formDisabled }"
        name="custodialWithdrawalAddress"
        type="text"
        margin="mt-2"
        placeholder="Enter wallet address"
        :value="custodialWithdrawalAddress"
        :disabled="formDisabled"
        :hide-error-messages="formDisabled"
        :show-validation-tick="custodialWithdrawalValid"
        center-align-errors
        @input="updateValues(values, meta); validateAddress()"
      />
    <InputValidated
      v-if="currency === 'XRP' || currency === 'EOS'"
      :class="{ 'opacity-40': formDisabled }"
      name="custodialWithdrawalTag"
      type="text"
      margin="mt-2"
      :placeholder="`Enter Destination ${currency === 'EOS' ? 'Memo' : 'Tag'} (Optional)`"
      :value="custodialWithdrawalTag"
      :disabled="formDisabled"
      center-align-errors
      @input="updateValues(values)"
    />
    </template>
    <p v-if="isCustodialWithdrawalMode" class="text-center mt-4">
      Available Balance:
      <span class="text-blue-500">
        {{ availableBalance }} {{ currency }}
      </span>
    </p>
    <div class="relative" :class="{ 'opacity-40': formDisabled }">
      <InputValidated
        name="swapAmount"
        type="number"
        step="0.01"
        :max-decimal-places="6"
        hide-validation-tick
        :value="swapAmount"
        :disabled="formDisabled"
        :currency="currency"
        error-friendly
        :hide-error-messages="formDisabled"
        hide-validation-cross
        center-align-errors
        @keypress="$preventLetterInput($event)"
        @input="updateValues(values)"
      />
      <span
        v-if="showExchangeRate"
        class="absolute block right-2 top-[2px] bg-transparent z-10 text-slate-100 select-none py-3 pr-1"
      >
        ~{{ valUsdDisplay }}
      </span>
      <div
        v-if="swapAmount && currencyFactor > 1"
        class="text-center mb-3 text-xs text-gray-400 -mt-1 font-bold"
      >
        X {{ currencyFactor }} = {{ $formatMoney(receiveAmount) }} {{ currencyBaseCode }}
      </div>
    </div>

    <div v-if="!isCustodialWithdrawalMode" class="flex justify-between mt-1">
      <ButtonPreset
        v-for="(preset, index) in presetsDisplay"
        :key="`preset-${index}`"
        :disabled="formDisabled || preset.disabled"
        :preset="preset.value"
        :preset-selected="swapAmount"
        :ignore-disabled-style="ignoreDisabledStyle"
        class="3xs:w-14 xs:w-[62px] text-sm xs:text-base"
        @click.prevent.stop="usePreset(preset.value)"
      >
        {{ preset.label }}
      </ButtonPreset>
    </div>
    <div v-if="isCustodialWithdrawalMode" class="details text-slate-400">
      <p class="flex justify-between">
        Withdraw amount: <span>{{ swapAmount || 0 }} {{ currency }}</span>
      </p>
      <p class="flex justify-between">
        Fee: <span>{{ fee }} {{ currency }}</span>
      </p>
      <p class="flex justify-between">
        Total withdraw amount: <span class="text-green-500">{{ totalWithdrawAmount }} {{ currency }}</span>
      </p>
      <p
        v-if="currencyFactor !== 1"
        class="flex justify-between"
        >
        Receive amount: <span>{{ $formatMoney(receiveAmount) }} {{ currencyBaseCode || currency }}</span>
      </p>
      <p v-if="currency === 'BTC'" class="mt-4 font-bold text-orange-500 text-xs text-center">Bitcoin withdrawals can take some time to process on the blockchain. You can check its status on your <NuxtLink to="/wallet?view=Transactions" class="text-white">transactions</NuxtLink> page.</p>
    </div>
    <transition name="fade-fast" mode="out-in">
      <ButtonButton
        v-if="showCompleteBtn"
        type="button"
        class="block w-full mt-6"
        theme="success"
        @click.prevent.stop="hideCompleteBtn"
      >
        {{ isCustodialWithdrawalMode ? 'Withdraw complete' : 'Swap complete' }}
      </ButtonButton>
      <ButtonButton
        v-else
        :is-loading="isLoading"
        type="submit"
        :disabled="!meta.valid || formDisabled || isSubmitBtnBlocked"
        :ignore-disabled-style="ignoreDisabledStyle"
        class="block w-full mt-4"
        @click.prevent.stop="onSubmit"
      >
        {{ isCustodialWithdrawalMode ? 'Withdraw' : 'Swap' }}
      </ButtonButton>
    </transition>
      <div v-if="locationRestricted" class="text-red text-center mt-4 text-sm font-bold">
        Sorry, {{ walletModalMode === 'deposit' ? 'deposits' : 'withdrawals' }} are not available from your location.
      </div>
  </VForm>
</template>

<script>
import * as VeeValidate from 'vee-validate';
import { mapActions, mapState } from 'pinia';
import * as yup from 'yup';
import BigNumber from 'bignumber.js';
import Web3 from 'web3';
import { validate } from 'multicoin-address-validator';

import { useCryptoStore } from '@/store/crypto';
import { useAuthStore } from '@/store/auth';
import { useBankingStore } from '@/store/banking';
import { useLocationStore } from '@/store/location';
import { getConfig } from '@/utils/getConfig';

export default defineComponent({
  name: 'ModalWalletFormInterface',
  components: {
    VForm: VeeValidate.Form,
  },
  props: {
    currency: {
      type: String,
      default: null,
    },
    currencyFactor: {
      type: Number,
      default: 1,
    },
    currencyBaseCode: {
      type: String,
      default: null,
    },
    presets: {
      type: Array,
      default: null,
    },
    minSwap: {
      type: [Number, String,],
      default: null,
    },
    maxSwap: {
      type: [Number, String,],
      default: null,
    },
    showExchangeRate: {
      type: Boolean,
      default: false,
    },
    walletModalMode: {
      type: String,
      default: null,
    },
    isProcessingTx: {
      type: Boolean,
      default: false,
    },
    isComplete: {
      type: Boolean,
      default: false,
    },
    isLoadingData: {
      type: Boolean,
      default: false,
    },
    limitRemaining: {
      type: String,
      default: null,
    },
    outcome: {
      type: String,
      default: null,
    },
    isSubmitBtnBlocked: {
      type: Boolean,
      default: false,
    },
    clientWalletBalance: {
      type: [String, Number,],
      default: null,
    },
    platformBalance: {
      type: [Number, String,],
      default: null,
    },
    maxLimit: {
      type: String,
      default: null,
    },
    walletModalFundsContext: {
      type: String,
      default: null,
    },
    fee: {
      type: [String, Number, BigNumber,],
      default: 0,
    },
    disableInput: {
      type: Boolean,
      default: false,
    },
    network: {
      type: String,
      default: null,
    },
  },
  emits: ['onSwapAmountUpdate', 'onSubmit', 'onComplete',],
  data() {
    return {
      swapAmount: null,
      showCompleteBtn: false,
      custodialWithdrawalAddress: null,
      custodialWithdrawalTag: null,
      custodialWithdrawalValid: false,
      paymentMethodOptionsLoading: false,
      paymentMethodOptionQuery: null,
      paymentMethodOptions: [],
      paymentMethodRef: null,
      formMeta: null,
    };
  },
  computed: {
    ...mapState(useCryptoStore, ['rates',]),
    ...mapState(useAuthStore, ['address',]),
    ...mapState(useBankingStore, ['allBalances',]),
    ...mapState(useLocationStore, ['restricted', 'isDepositRestricted', 'isWithdrawalRestricted',]),
    validationSchema() {
      const shape = {
        swapAmount: yup
        .number()
        .positive()
        .min(this.minSwap, `Minimum ${this.isCustodialWithdrawalMode ? 'withdrawal' : 'swap'} is ${this.minSwap} ${this.currency}`)
        .max(this.maxSwapInMode, this.maxSwapErrorMsg)
        .required('Please enter a numeric value')
        .typeError('Please enter a numeric value')
        .label('Swap amount'),
      };

      if (this.isCustodialWithdrawalMode && !this.paymentMethodRef) {
        shape.custodialWithdrawalAddress = yup
        .string()
        .required('Please enter a wallet address')
        .test('custodialWithdrawalValid', 'Please enter a valid wallet address', () => this.custodialWithdrawalValid)
        .label('Wallet Address');
      }
      return yup.object().shape(shape);
    },
    maxSwapInMode() {
      return this.maxSwap;
    },
    maxSwapErrorMsg() {
      switch (true) {
        case this.walletModalMode === 'deposit':
          return `Maximum swap is ${this.maxSwapInMode} ${this.currency}`;
        case this.maxLimit === null:
          return '';
        case new BigNumber(this.availableBalance).isLessThan(this.maxLimit):
          return `Maximum ${this.walletModalMode === 'custodialWithdrawal' ? 'withdrawal' : 'swap'} is ${this.maxSwapInMode} ${this.currency}`;
        default:
          return `${this.walletModalMode === 'custodialWithdrawal' ? 'Withdrawal' : 'Swap'} is limited to ${this.maxLimit} ${this.currency} per 24h. Please select a lower amount or try again later.`;
      }
    },
    formDisabled() {
      return this.locationRestricted || !!(this.isProcessingTx || ['NotPermitted', 'MaximumPendingWithdrawals',].includes(this.outcome) || this.maxSwapInMode === 0 || this.disableInput);
    },
    isLoading() {
      return !!this.isProcessingTx;
    },
    limitRemainingBn() {
      return new BigNumber(this.limitRemaining || 0);
    },
    platformBalanceBn() {
      return new BigNumber(this.platformBalance || 0);
    },
    ignoreDisabledStyle() {
      return !!this.isLoadingData;
    },
    withdrawalFee() {
      return new BigNumber(this.fee || 0);
    },
    locationRestricted() {
      return this.walletModalMode === 'deposit' ? this.isDepositRestricted : this.isWithdrawalRestricted;
    },
    receiveAmount() {
      if (this.currencyFactor === 1) {
        return this.swapAmount;
      }

      return new BigNumber(this.swapAmount || 0).multipliedBy(this.currencyFactor).toNumber();
    },
    presetsDisplay() {
      const presets = this.presets.map((preset) => {
        return {
          value: preset === 'MAX' ? this.maxSwap : preset,
          disabled: false,
          label: preset === 'MAX' ? 'MAX' : `${preset}`,
        };
      });

      if (!this.isLoadingData) {
        presets.forEach((preset) => {
          let disabled = false;

          const presetBn = new BigNumber(preset.value);
          if (this.walletModalMode === 'deposit') {
            disabled = false;
          } else if (presetBn.isLessThan(this.platformBalanceBn) || presetBn.isLessThan(this.limitRemainingBn)) {
            disabled = false;
          } else if (preset.label === 'MAX' || (presetBn.isLessThan(this.platformBalanceBn) && presetBn.isLessThan(this.limitRemainingBn))) {
            disabled = false;
          } else {
            disabled = true;
          }

          preset.disabled = disabled;
        });
      }

      return presets;
    },
    valUsdDisplay() {
      const priceUsd = this.swapAmount * this.exchangeRateUsd;
      return `$${this.$truncateNumber(priceUsd, 2)}`;
    },
    exchangeRateUsd() {
      return this.rates?.[this.currency]?.USD || 0;
    },
    paymentMethodType() {
      switch (this.network) {
        case 'Arbitrum':
        case 'Optimism':
        case 'Base':
        case 'Binance':
        case 'Polygon':
          return 'Ethereum';
        default:
          return this.network;
      }
    },
    isCustodialWithdrawalMode() {
      return this.walletModalMode === 'custodialWithdrawal';
    },
    availableBalance() {
      return !this.locationRestricted ? this.allBalances?.[this.currency]?.accounts.custodial : 0;
    },
    totalWithdrawAmount() {
      const totalAmount = this.swapAmount ? BigNumber.max(new BigNumber(this.swapAmount || 0).minus(new BigNumber(this.withdrawalFee)), 0).toNumber() : 0;

      return this.$convertExponentialNumber(totalAmount);
    },
    isWithdraw() {
      return ['withdraw', 'custodialWithdrawal',].includes(this.walletModalMode);
    },
  },
  watch: {
    swapAmount(val) {
      this.$emit('onSwapAmountUpdate', val);
    },
    isComplete(val) {
      this.showCompleteBtn = val;
    },
    walletModalMode() {
      this.swapAmount = null;
      this.$refs.SwapForm.resetForm();
      this.focusOnField();
    },
    async currency() {
      this.swapAmount = null;
      this.$refs.SwapForm.resetForm();
    },
    async network() {
      this.swapAmount = null;
      this.$refs.SwapForm.resetForm();

      if (this.locationRestricted) {
        return;
      }

      await this.loadPaymentMethodOptions();
      this.validateAddress();
    }
  },
  async created() {
    this.focusOnField();
    this.custodialWithdrawalAddress = !this.isCustodialWithdrawalMode && this.address ? this.address : undefined;

    if (this.isCustodialWithdrawalMode && !this.locationRestricted) {
      await this.loadPaymentMethodOptions();
    }
  },
  methods: {
    ...mapActions(useBankingStore, ['getWithdrawalPaymentMethods',]),
    updateValues(values, meta) {
      this.formMeta = meta;
      this.swapAmount = values.swapAmount > 0 ? this.$convertExponentialNumber(values.swapAmount) : 0;

      if (this.isCustodialWithdrawalMode) {
        this.custodialWithdrawalAddress = values.custodialWithdrawalAddress;
      }

      if (this.isCustodialWithdrawalMode && this.currency === 'XRP' || this.currency === 'EOS') {
        this.custodialWithdrawalTag = values.custodialWithdrawalTag;
      }
    },
    onSubmit() {
      const data = {
        amount: this.swapAmount,
        context: this.walletModalFundsContext,
      };

      if (this.paymentMethodRef || this.custodialWithdrawalAddress) {
        data.paymentMethod = {
          type: this.paymentMethodType,
          providerRef: this.paymentMethodRef || this.custodialWithdrawalAddress,
        };
      }

      if (this.custodialWithdrawalTag) {
        data.tag = this.custodialWithdrawalTag;
      }

      this.$emit('onSubmit', data);
    },
    usePreset(val) {
      this.swapAmount = val;
    },
    hideCompleteBtn() {
      this.$emit('onComplete', this.currency, this.walletModalFundsContext, false);
    },
    focusOnField() {
      const elementId = this.isCustodialWithdrawalMode ? 'custodialWithdrawal' : 'swapAmount';
      const element = document.getElementById(elementId);

      if (element) {
        element.focus();
      }
    },
    validateAddress() {
      if (!this.custodialWithdrawalAddress) {
        this.custodialWithdrawalValid = false;
        return;
      }

      switch (this.paymentMethodType) {
        case 'Bitcoin':
          this.custodialWithdrawalValid = validate(this.custodialWithdrawalAddress, 'bitcoin', getConfig('ENV_NAME') !== 'Dev' ? 'prod' : 'testnet');
          break;
        case 'Solana':
          this.custodialWithdrawalValid = validate(this.custodialWithdrawalAddress, 'solana', getConfig('ENV_NAME') !== 'Dev' ? 'prod' : 'testnet');
          break;
        case 'Litecoin':
          this.custodialWithdrawalValid = validate(this.custodialWithdrawalAddress, 'litecoin', getConfig('ENV_NAME') !== 'Dev' ? 'prod' : 'testnet');
          break;
        case 'Ripple':
          this.custodialWithdrawalValid = validate(this.custodialWithdrawalAddress, 'ripple', getConfig('ENV_NAME') !== 'Dev' ? 'prod' : 'testnet');
          break;
        case 'Tron':
          this.custodialWithdrawalValid = validate(this.custodialWithdrawalAddress, 'tron', getConfig('ENV_NAME') !== 'Dev' ? 'prod' : 'testnet');
          break;
        case 'EOS':
          this.custodialWithdrawalValid = validate(this.custodialWithdrawalAddress, 'eos', getConfig('ENV_NAME') !== 'Dev' ? 'prod' : 'testnet');
          break;
        case 'Dogecoin':
          this.custodialWithdrawalValid = validate(this.custodialWithdrawalAddress, 'dogecoin', getConfig('ENV_NAME') !== 'Dev' ? 'prod' : 'testnet');
          break;
        case 'BitcoinCash':
          this.custodialWithdrawalValid = validate(this.custodialWithdrawalAddress, 'bitcoincash', getConfig('ENV_NAME') !== 'Dev' ? 'prod' : 'testnet');
          break;
        default:
          this.custodialWithdrawalValid = Web3.utils.isAddress(this.custodialWithdrawalAddress);
          break;
      }
    },
    async loadPaymentMethodOptions() {
      this.paymentMethodOptionsLoading = true;
      const provider = this.walletModalFundsContext === 'Custodial' ? 'Fireblocks' : 'Ethereum';
      const methods = await this.getWithdrawalPaymentMethods([this.paymentMethodType,], [provider,]);
      this.paymentMethodOptions = methods.map(x => ({ value: x?.address, text: x?.address, }));
      this.setPaymentMethodOptionQuery();
      this.paymentMethodOptionsLoading = false;
    },
    setPaymentMethodOptionQuery() {
      this.paymentMethodOptionQuery = { value: '0', text: 'Choose existing address', };
    },
    onPaymentMethodOptionSelected(option) {
      if (!option) {
        this.setPaymentMethodOptionQuery();
        this.paymentMethodRef = null;
        return;
      }

      this.paymentMethodOptionQuery = option;
      this.paymentMethodRef = option.value;
    },
  },
});
</script>
